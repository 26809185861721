import Crud from '@/store/classes/crud'
import ApiHelper from '@/helpers/ApiHelper'
import { JOB_SERVER, JOB_SERVER_API_KEY } from '@/servers';

const crud = new Crud({
    module: 'report'
})

export default {
    namespaced: true,
    state: {},
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions,
        tasksScoresList (context, form) {
            return ApiHelper.request(
                `${JOB_SERVER}/api/report/tasks-scores-list`,
                'get', {}, form,
                { api_key: JOB_SERVER_API_KEY, responseType: 'blob' }
            )
        },
        themeDifference (context, form) {
            return ApiHelper.request(
                `${JOB_SERVER}/api/report/theme-difference`,
                'get', {}, form,
                { api_key: JOB_SERVER_API_KEY }
            )
        },
        allTasksThemes (context, form) {
            return ApiHelper.request(
                `${JOB_SERVER}/api/report/all-tasks-themes`,
                'get', {}, form,
                { api_key: JOB_SERVER_API_KEY, responseType: 'blob' }
            )
        }
    },
    getters: {
        ...crud.getters
    }
}