import Login from '@/views/Login.vue'
import Error404 from '@/views/Error404.vue'

import crud from './crud'
import breadcrumbs from './breadcrumbs'

export default [{
        path: "/",
        redirect: "/task"
    }, {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            layout: 'login'
        }
    },
    // Modules CRUD
    ..._.flatten(_.map(crud, (item) => {
        return [{
                path: `/${item.module}`,
                component: () => import(`@/views/${item.module}/Index.vue`),
                meta: {
                    breadcrumbs: breadcrumbs[item.module].index
                }
            },
            {
                path: `/${item.module}/create`,
                component: () => import(`@/views/${item.module}/Create.vue`),
                meta: {
                    breadcrumbs: breadcrumbs[item.module].create
                }
            },
            {
                path: `/${item.module}/update/:id`,
                component: () => import(`@/views/${item.module}/Update.vue`),
                meta: {
                    breadcrumbs: breadcrumbs[item.module].update
                }
            }
        ];
    })),
    {
        path: `/scoring-criteria/task-relations`,
        component: () => import(`@/views/scoring-criteria/TaskRelations.vue`),
        meta: {
            breadcrumbs: [
                { ...breadcrumbs['scoring-criteria'].index[0], disabled: false },
                {
                    text: `Связи с заданиями`,
                    disabled: true
                }
            ]
        }
    },
    // Task
    {
        path: `/task`,
        component: () => import(`@/views/task/Index.vue`)
    },
    {
        path: `/task/themes-upload`,
        component: () => import(`@/views/task/ThemesUpload.vue`),
        meta: {
            breadcrumbs: [
                { ...breadcrumbs.task.index[0], disabled: false },
                {
                    text: `Загрузка тем`,
                    disabled: true
                }
            ]
        }
    },
    {
        path: `/task/export`,
        component: () => import(`@/views/task/Export.vue`),
        meta: {
            breadcrumbs: [
                { ...breadcrumbs.task.index[0], disabled: false },
                {
                    text: `Экспорт`,
                    disabled: true
                }
            ]
        }
    },
    {
        path: `/task/create`,
        component: () => import(`@/views/task/Create.vue`)
    },
    {
        path: `/task/update/:id`,
        component: () => import(`@/views/task/Update.vue`)
    },
    {
        path: `/task/preview/:item_id+`,
        component: () => import(`@/views/task/Preview.vue`),
        meta: {
            layout: 'print'
        }
    },
    {
        path: `/settings/swap-options`,
        name: 'SwapOptions',
        component: () => import(`@/views/settings/SwapOptions.vue`),
        meta: {
            breadcrumbs: [
                {
                    text: 'Главная',
                    disabled: false
                },
                {
                    text: 'Смена вариантов работ и заданий',
                    disabled: true
                }
            ]
        }
    },
    {
        path: `/settings/scores-report`,
        component: () => import(`@/views/settings/ScoresReport.vue`),
        meta: {
            breadcrumbs: [
                { ...breadcrumbs.task.index[0], disabled: false },
                {
                    text: `Выгрузка оценок`,
                    disabled: true
                }
            ]
        }
    },
    {
        path: `/settings/task-to-theme-report`,
        component: () => import(`@/views/settings/TaskToThemeReport.vue`),
        meta: {
            breadcrumbs: [
                { ...breadcrumbs.task.index[0], disabled: false },
                {
                    text: `Таблица разницы тем в ВПР и контрольных работах`,
                    disabled: true
                }
            ]
        }
    },
    {
        path: `/settings/reports`,
        component: () => import(`@/views/settings/Reports.vue`),
        meta: {
            breadcrumbs: [
                { ...breadcrumbs.task.index[0], disabled: false },
                {
                    text: `Другие отчёты`,
                    disabled: true
                }
            ]
        }
    },
    // Content kits
    {
        path: `/content-kit`,
        component: () => import(`@/views/content-kit/Index.vue`)
    },
    // Job
    {
        path: `/job/update/:id`,
        component: () => import(`@/views/job/Update.vue`)
    },
    {
        path: `/job/answers-pdf`,
        component: () => import(`@/views/job/AnswersPdf.vue`),
        meta: {
            breadcrumbs: [
                { ...breadcrumbs.job.index[0], disabled: false },
                {
                    text: 'Ответы в pdf',
                    disabled: true
                }
            ]
        }
    },
    {
        path: `/media-manager`,
        component: () => import(`@/views/media-manager/index.vue`),
        meta: {
            breadcrumbs: [{
                text: 'Медиа менеджер',
                disabled: true
            }]
        }
    },
    {
        path: '/lesson-plan/preview/:item_id+',
        component: () => import(`@/views/lesson-plan/Preview.vue`),
        meta: {
            breadcrumbs: [
                {
                    text: 'Главная',
                    disabled: false,
                    href: '/'
                },
                {
                    text: 'Поурочное планирование',
                    disabled: false,
                    href: '/lesson-plan'
                },
                {
                    text: 'Превью поурочного планирования',
                    disabled: true
                }
            ]
        }
    },
    // -----
    // Error 404
    {
        path: '*',
        component: Error404,
        meta: {
            breadcrumbs: [{
                    text: 'Главная',
                    disabled: false,
                    href: '/'
                },
                {
                    text: 'Страница не найдена',
                    disabled: true
                }
            ]
        }
    }
]